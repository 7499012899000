import { defineStore, acceptHMRUpdate } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'
import uniq from 'lodash/uniq'
import { useInstructorStore } from '@/stores/InstructorStore'
import { useUserStore } from '@/stores/UserStore'
import { getInstructorImageUrl, normalize } from './services/instructor'

// import capitalize from 'lodash/capitalize';

import { usDateFormatToYYYYMMDD, getNumberOfDays, timestampsToDateRange } from '@/helpers/datetime'
import { capitalizeFirstLetter, cleanMarkup } from '@/helpers/text'

const basename = (path) => {
  let parts = path.split('/')
  if (parts.length) {
    return parts[parts.length - 1]
  }
  return null
}

const getClassImageUrl = (imageUrl) => {
  let url
  if (imageUrl) {
    const imageName = basename(imageUrl)
    if (siteConfig.features?.cdn?.url) {
      // if (siteConfig.features.cdn.type === 'keycdn') {
      url = `${siteConfig.features.cdn.url}/library/system/${imageName}?width=986&height=740&fit=cover&enlarge=0&metadata=0`
    } else {
      url = `${siteConfig.legacyImagesBaseUrl}/system/${imageName}`
    }
  } else {
    url = `/sites/${siteConfig.key}/${siteConfig.assets.defaultClassImage}`
  }
  return url
}

const daySortPos = {
  Monday: 0,
  Tuesday: 1,
  Wednesday: 2,
  Thursday: 3,
  Friday: 4,
  Saturday: 5,
  Sunday: 6,
}

const noFilters = {
  semester: null,
  site: null,
  location: null,
  locationType: null,
  category: null,
  medium: null,
  program: null,
  // duration: null,
  level: null,
  instructor: null,
  day: null,
  dateRange: null,
  type: null,
  ageRange: null,
}

export const useClassStore = defineStore('ClassStore', {
  state: () => {
    return {
      openClasses: [],
      openWorkshops: [],
      openClassProgramIds: [],
      programs: [],
      programIdsByName: {},
      semesters: [],
      mediums: [],
      programMediums: [],
      secondaryMediums: [],
      categories: [],
      levels: [],
      locations: [],
      sites: [],
      filters: noFilters,
      hiddenFilters: { division: null },
      loadingClasses: false,
      loadingPrograms: false,
      loadingInstructors: false,
      classRegistrationCountsById: {},
      sessionRegistrationCountsById: {},
      roster: {},
      loadingRoster: false,
    }
  },

  getters: {
    loading() {
      const instructorStore = useInstructorStore()

      return this.loadingClasses || instructorStore.loadingInstructors || this.loadingPrograms
    },

    programsWithOpenClasses() {
      const programs = this.programs.filter((p) => this.openClassProgramIds.includes(p.eventId)).map((p) => p.title)

      if (siteConfig.features.programOrder) {
        const order = siteConfig.features.programOrder
        return programs.sort((a, b) => {
          // console.warn('order', a, order.indexOf(a), b, order.indexOf(b))
          if (order.indexOf(a) > order.indexOf(b)) return 1
          if (order.indexOf(a) < order.indexOf(b)) return -1
          return 0
        })
      } else {
        return programs
      }
    },

    programsById() {
      if (!this.programs.length) return {}

      if (!this.openClasses.length) return {}

      const programMap = {}

      this.programs.forEach((program) => {
        programMap[program.eventId] = program
      })

      return programMap
    },

    queryParams(state) {
      const params = {}
      Object.keys(this.filters).forEach((filter) => {
        if (this.filters[filter] !== null) {
          params[filter] = this.filters[filter]
        }
      })
      return params
    },

    filteredList(state) {
      const instructorStore = useInstructorStore()

      let list = [...this.openClasses]
      // Hides classes that havent yet started unless in the group with id 432

      console.log('this.filters', this.filters)
      // list.forEach(c => {
      //   console.log(c.shortTitle, c.site, 'startDateStandard', c.startDateStandard, 'endDateStandard', c.endDateStandard)
      // })

      if (!siteConfig.features.showClassesAfterCutOffDate) {
        list = list.filter((c) => !c.isPastCutOffDate)
      }

      if (['kershaw', 'ocean-city'].includes(siteConfig.key)) {
        list = list.filter((c) => !c.udef2)
      }

      if (this.filters.dateRange) {
        console.log('filtering dateRange')
        const parts = this.filters.dateRange.split('_')
        const startDate = parts[0]

        if (parts.length === 2) {
          const endDate = parts[1]
          console.log('startDate', startDate, 'endDate', endDate)
          list = list.filter((c) => {
            if (c.daySales || siteConfig.features.classFilters.showStartedClassesInDateRangeFilter) {
              const validDays = c.classDays.filter((d) => {
                return d.dateStandard >= startDate && d.dateStandard <= endDate
              })
              return validDays.length > 0
            } else {
              return c.startDateStandard >= startDate && c.startDateStandard <= endDate
            }
          })
        } else {
          list = list.filter((c) => {
            if (c.daySales || siteConfig.features.classFilters.showStartedClassesInDateRangeFilter) {
              const validDays = c.classDays.filter((d) => {
                return d.dateStandard >= startDate
              })
              return validDays.length > 0
            } else {
              return c.startDateStandard >= startDate
            }
          })
        }
      }

      if (this.filters.ageRange && siteConfig.features.classFilters.ageRangeFilters) {
        console.log('filtering ageRange')
        const rules = siteConfig.features.classFilters.ageRangeFilters.find((f) =>
          f.name.includes(this.filters.ageRange)
        )

        list = list.filter((item) => {
          // console.log('item', item.minAge, item.maxAge, 'rules', rules.minAge, rules.maxAge)
          // XXX check this again, looks strange
          if (!item.minAge || !item.maxAge) return true

          if (item.minAge > rules.maxAge) return false

          if (item.maxAge < rules.minAge) return false

          if (item.minAge <= rules.minAge && item.maxAge <= rules.maxAge) return true

          if (rules.minAge >= item.minAge) return true

          if (item.minAge >= rules.minAge || item.maxAge <= rules.maxAge) return true

          return false
        })
      }

      if (this.hiddenFilters.division) {
        // console.log('this.hiddenFilters.division', this.hiddenFilters.division)
        list = list.filter((c) => c.division === this.hiddenFilters.division)
      }

      if (this.filters.type) {
        list = list.filter((c) => c.division === this.filters.type)
      }

      if (this.filters.semester) {
        list = list.filter((c) => c.semesterName.trim() === this.filters.semester)
      }

      if (this.filters.site) {
        list = list.filter((c) => c.site === this.filters.site)
      }

      if (this.filters.location) {
        list = list.filter((c) => c.location === this.filters.location)
      }

      if (this.filters.locationType) {
        list = list.filter((c) => c.locationType === this.filters.locationType)
      }

      if (this.filters.category) {
        // department == category
        list = list.filter((c) => c.department === this.filters.category)
      }

      if (this.filters.program) {
        list = list.filter((c) => c.eventId === this.programIdsByName[this.filters.program])
      }

      if (this.filters.medium) {
        list = list.filter((c) => {
          return (
            c.medium === this.filters.medium || c.medium1 === this.filters.medium || c.medium2 === this.filters.medium
          )
        })
      }

      if (this.filters.level) {
        list = list.filter((c) => c.classLevel === this.filters.level)
      }

      if (this.filters.instructor) {
        // console.log('this.filters.instructor', this.filters.instructor)
        list = list.filter((c) => {
          return (
            instructorStore.instructorsById[c.instructorId]?.fullName.trim() === this.filters.instructor.trim() ||
            instructorStore.instructorsById[c.instructor2Id]?.fullName.trim() === this.filters.instructor.trim()
          )
        })
      }

      if (this.filters.day) {
        list = list.filter((c) => {
          const validDays = c.classDays.filter((d) => d.classDay === this.filters.day)
          return validDays.length > 0
        })
      }
      
      return list
    },
  },

  actions: {
    async sendMessageClass(classId, message) {
      try {
        await axios.post(`${siteConfig.apiBaseUrl}/classes/${classId}/message`, { message })
      } catch (error) {
        console.log('error - sendMessageClass', error)
      }
    },

    async fetchReportRoster(classDetails) {
      this.loadingRoster = true

      try {
        const response = await axios.get(
          `https://storefront-api.artcentercanvas.com/v1/${siteConfig.key}/reports/roster/${classDetails.catalogId}/data`
        )

        this.roster = response.data

        console.log('roster', response.data)
        this.loadingRoster = false
      } catch (error) {
        this.loadingRoster = false
        console.log('error - fetchReportRoster', error)
      }
    },

    removeDisplayOnly() {
      console.log('classStore.removeDisplayOnly')
      const newOpenClasses = []
      const userStore = useUserStore()
      this.openClasses.forEach((cls) => {
        const newClass = { ...cls }
        const isSnowFarmResidency = siteConfig.key === 'snowfarm' && newClass.shortTitle.includes('Residency')
        const isSnowFarmOpenStudioResidency =
          siteConfig.key === 'snowfarm' && newClass.shortTitle.includes('Open Studio Residency')
        const canRegisterForResidency =
          isSnowFarmOpenStudioResidency && userStore.loggedIn && userStore.groupIds.includes(410)
        // console.log('canRegisterForResidency', canRegisterForResidency)
        if (!isSnowFarmResidency || (isSnowFarmOpenStudioResidency && canRegisterForResidency)) {
          newClass.displayOnly = false
        }
        newOpenClasses.push(newClass)
      })
      this.openClasses = newOpenClasses
    },

    resetFilters() {
      Object.keys(this.filters).forEach((key) => {
        this.filters[key] = null
      })
    },

    applyFilters(filters) {
      console.log('applyFilters', filters)
      Object.keys(filters).forEach((key) => {
        this.filters[key] = filters[key]
      })
    },

    fetchRegistrationCounts() {
      const url = `https://storefront-api.artcentercanvas.com/v1/${siteConfig.key}/reports/queries/class-registration-counts`

      axios
        .get(url)
        .then((response) => {
          const data = response.data
          if (!data.ok) return
          const classCounts = {}
          const sessionCounts = {}
          data.results.forEach((item) => {
            if (item.schedule_id) {
              sessionCounts[item.schedule_id] = item.registration_count
            } else {
              classCounts[item.catalog_id] = item.registration_count
            }
          })
          this.classRegistrationCountsById = classCounts
          this.sessionRegistrationCountsById = sessionCounts
          console.log('classRegistrationCounts', this.classRegistrationCountsById)
          console.log('sessionRegistrationCoutns', this.sessionRegistrationCountsById)
        })
        .catch((error) => {
          console.log('Error fetching registration counts', error)
        })
    },

    fetchInstructors() {
      this.loadingInstructors = true
      console.log('fetchInstructors')

      // const req = axios.get(`${siteConfig.legacyApiBaseUrl}/classes/allinstructors`, {
      //   auth: siteConfig.legacyApiAuth
      // })

      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/instructors`)

      req
        .then((response) => {
          let instructors = response.data

          const instructorsById = {}
          // console.log('instructors', instructors)
          instructors.forEach((instructor) => {
            instructorsById[instructor.instructorId] = instructor
            instructor.imageUrl = getInstructorImageUrl(instructor.imageUrl)
          })
          // console.log('instructorsById', instructorsById)
          this.instructorsById = instructorsById

          if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
            instructors = instructors.filter(
              (inst) => !siteConfig.features.instructorExclusions.includes(inst.fullName)
            )
          }

          this.instructors = instructors.sort((a, b) => {
            // console.log(normalize(a.lastName))
            if (normalize(a.lastName) < normalize(b.lastName)) {
              return -1
            } else if (normalize(a.lastName) > normalize(b.lastName)) {
              return 1
            }
            return 0
          })
          console.log(instructors)

          this.loadingInstructors = false
        })
        .catch((error) => {
          this.loadingInstructors = false
          console.log('error', error)
        })
        .finally(() => {
          if (siteConfig.key === 'perkins') {
            this.fetchInstructorGroups()
          }
        })
    },

    async fetchInstructorGroups() {
      try {
        const response = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/perkins/instructor-groups`)

        const groups = response.data.results

        if (groups.length) {
          groups.forEach((group) => {
            this.groupsForInstructorId[group.person_id] = this.groupsForInstructorId[group.person_id] || []
            this.groupsForInstructorId[group.person_id].push(group.group_name)
          })
        }
      } catch (e) {
        console.log('fetchInstructorGroups error', e)
      }
    },

    fetchPrograms() {
      this.loadingPrograms = true
      console.log('fetchPrograms')

      // const req = axios.get(`${siteConfig.legacyApiBaseUrl}/classes/allprograms`, {
      //   auth: siteConfig.legacyApiAuth
      // })

      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/programs`)

      req
        .then((response) => {
          const programs = response.data
          console.log('programs', response.data)

          this.programs = response.data

          this.programs.forEach((p) => {
            this.programIdsByName[p.title] = p.eventId
          })

          this.loadingPrograms = false
        })
        .catch((error) => {
          this.loadingPrograms = false
          console.log('error', error)
        })
    },

    fetchOpenClasses() {
      // TEMPORARY - hide all classes unless in the group with id 432
      // const userStore = useUserStore()
      // if (siteConfig.key === 'snowfarm' && !userStore.groupIds.includes(432)) {
      //   return
      // }
      // END TEMPORARY

      const instructorStore = useInstructorStore()

      this.loadingClasses = true
      console.log('fetchOpenClasses')

      if (this.openClasses.length) {
        console.log('classes already loaded')
        return
      }

      // const req = axios.get(`${siteConfig.legacyApiBaseUrl}/classes/allopenclasses`, {
      //   auth: siteConfig.legacyApiAuth
      // })

      const req = axios.get(`${siteConfig.apiBaseUrl}/classes/open`)

      req
        .then((response) => {
console.log('open classes response.data', response.data)
          let openClasses = response.data

          if (siteConfig.features.classFilters.semesterWhitelist) {
            console.log('filtering semesters')
            openClasses = openClasses.filter((c) =>
              siteConfig.features.classFilters.semesterWhitelist.includes(c.semesterId)
            )
          }

          let today = new Date()
          let todaysDateStandard = today.toISOString().split('T')[0]

          if (siteConfig.features?.useUserDefinedField1AsGroupWhitelist) {

            const userStore = useUserStore()

            openClasses = openClasses.filter((cls) => {
              // get all comma seperated numbers from udef1 as whitelist group ids
              const whiteListGroupIds = cls.udef1?.split(',')?.map(g => g.trim())?.filter(val => val !== '')?.filter(g => !isNaN(g))
              if (whiteListGroupIds.length){
                return Object.values(userStore?.groupIds)?.some(value => whiteListGroupIds.includes(value.toString()));
              }
              return true
            })
          }

          openClasses.forEach((cls) => {
            cls.images = []
            cls.imageUrl = getClassImageUrl(cls.imageUrl)

            cls.images.push(cls.imageUrl)

            if (cls.imageUrl2) {
              cls.imageUrl2 = getClassImageUrl(cls.imageUrl2)
              cls.images.push(cls.imageUrl2)
            }

            if (cls.imageUrl3) {
              cls.imageUrl3 = getClassImageUrl(cls.imageUrl3)
              cls.images.push(cls.imageUrl3)
            }
            if (cls.imageUrl4) {
              cls.imageUrl4 = getClassImageUrl(cls.imageUrl4)
              cls.images.push(cls.imageUrl4)
            }

            cls.startDateStandard = usDateFormatToYYYYMMDD(cls.startDate)
            cls.endDateStandard = usDateFormatToYYYYMMDD(cls.endDate)
            cls.registrationCutOffDateStandard = usDateFormatToYYYYMMDD(cls.registrationCutOff)
            cls.hasStarted = todaysDateStandard > cls.startDateStandard
            cls.startsToday = todaysDateStandard === cls.startDateStandard

            if (cls.registrationCutOffDateStandard) {
              cls.isPastCutOffDate = cls.registrationCutOffDateStandard < todaysDateStandard
            } else {
              cls.isPastCutOffDate = false
            }

            cls.ages = ''
            let ageUnitMin = cls.ageUnitMin || ''
            let ageUnitMax = cls.ageUnitMax || ''
            if (cls.minAge && cls.maxAge) {
              cls.ages += `${cls.minAge} ${ageUnitMin}`
              if (cls.maxAge >= 60) {
                if (cls.minAge < 18) {
                  cls.ages += ` through adult`
                } else {
                  cls.ages += `+`
                }
              } else {
                cls.ages += `-${cls.maxAge} ${ageUnitMax}`
              }
            } else if (cls.minAge && !cls.maxAge) {
              if (cls.minAge < 18) {
                cls.ages += `${cls.minAge} ${ageUnitMin}+`
              } else {
                cls.ages += `${cls.minAge} ${ageUnitMin}+`
              }
            }

            cls.ages = `Ages ${cls.ages}`

            if (siteConfig.key === 'snowfarm') {
              if (cls.ages === 'Ages 13 through adult') {
                cls.ages = 'Adults & Teens'
              } else if (cls.ages === 'Ages 18+') {
                cls.ages = 'Adults'
              }
            }

            if (siteConfig.features?.cleanDescriptionMarkup === false && cls.descr && cls.descr.includes('<')) {
              // console.warn('raw description')
              cls.description = cls.descr
            } else {
              cls.description = cleanMarkup(cls.descr)
              // console.log('cls.description', cls.description)
            }

            cls.locationType = 'On-site'
            if (cls.location.toLowerCase().includes('off-site')) {
              cls.locationType = 'Off-site'
            } else if (cls.location.toLowerCase().includes('online')) {
              cls.locationType = 'Online'
            }

            cls.originalClassDayCount = cls.classDays.length
            cls.classDays = cls.classDays.filter(
              (d) => usDateFormatToYYYYMMDD(d.dateFmt) >= todaysDateStandard && d.available && d.publish
            )

            cls.classDays.forEach((d) => {
              d.dateStandard = usDateFormatToYYYYMMDD(d.dateFmt)
            })

            // XXX use dateStandard from above
            cls.classDays = cls.classDays.sort((a, b) => {
              if (
                `${usDateFormatToYYYYMMDD(a.dateFmt)}-${a.stTime}` < `${usDateFormatToYYYYMMDD(b.dateFmt)}-${b.stTime}`
              )
                return -1
              if (
                `${usDateFormatToYYYYMMDD(a.dateFmt)}-${a.stTime}` > `${usDateFormatToYYYYMMDD(b.dateFmt)}-${b.stTime}`
              )
                return 1
              return 0
            })

            let allClassDays = cls.classDays.map((day) => ({
              day: day.classDay,
              dayOfWeek: day.dayOfWeek,
              date: day.dateFmt,
              startTime: day.startTimeFmt,
              endTime: day.endTimeFmt,
            }))
            if (cls.catalogId === 7300) console.log('allClassDays', allClassDays, cls.classDays)

            // if (cls.catalogId === 1025) console.log('allClassDays', allClassDays)

            const uniqueStartTimes = Array.from(new Set(allClassDays.map((d) => d.startTime)))
            const uniqueEndTimes = Array.from(new Set(allClassDays.map((d) => d.endTime)))
            const uniqueClassDates = Array.from(new Set(allClassDays.map((d) => d.date)))
            const uniqueClassDays = Array.from(new Set(allClassDays.map((d) => d.day))).sort((a, b) => {
              if (daySortPos[a] < daySortPos[b]) return -1
              if (daySortPos[a] > daySortPos[b]) return 1
              return 0
            })

            cls.uniqueClassDays = uniqueClassDays

            // console.log('allClassDays', allClassDays)
            // console.log('uniqueClassDays', uniqueClassDays)
            let instructorExclusions = []
            if (siteConfig.features.instructorExclusions && siteConfig.features.instructorExclusions.length) {
              instructorExclusions = siteConfig.features.instructorExclusions
            }

            cls.instructorSummary = ''

            if (!instructorExclusions.includes(cls.instructorName)) {
              cls.instructorSummary += cls.instructorName
            }

            if (cls.instructor2Id) {
              const instructor2 = instructorStore.instructorsById[cls.instructor2Id]
              if (instructor2 && !instructorExclusions.includes(instructor2.fullName)) {
                if (cls.instructorSummary) {
                  cls.instructorSummary += ` and ${instructor2.fullName}`
                } else {
                  cls.instructorSummary += instructor2.fullName
                }
              }
            }

            cls.classDaySummary = null
            if (!siteConfig.features.hideClassDay) {
              // if (cls.catalogId === 780) console.log('uniqueClassDays', uniqueClassDays)
              if (cls.daySales) {
                if (uniqueClassDays.length === 1) {
                  if (uniqueClassDates.length > 1) {
                    cls.classDaySummary = uniqueClassDays[0] + 's'
                  } else {
                    cls.classDaySummary = uniqueClassDays[0]
                  }
                } else {
                  cls.classDaySummary = 'Various days'
                }
                // if (cls.classDays.length > 1) {
                //   cls.classDaySummary = 'Various days'
                // } else if (cls.classDays.length === 0) {
                //   cls.classDaySummary = uniqueClassDays[0]
                // }
              } else if (uniqueClassDates.length === 1) {
                cls.classDaySummary = uniqueClassDays[0]
              } else if (uniqueClassDays.length === 1) {
                if (cls.sessions > 1) {
                  cls.classDaySummary = uniqueClassDays[0] + 's'
                } else {
                  cls.classDaySummary = uniqueClassDays[0]
                }
                // XXX Pittsburgh - not quite right, see notes/recording
                // } else if (siteConfig.copy?.classes?.datesPrefix) {
                // cls.classDaySummary = siteConfig.copy?.classes?.datesPrefix
              } else if (uniqueClassDays.length === 2) {
                if (cls.sessions == uniqueClassDays.length) {
                  cls.classDaySummary = `${uniqueClassDays[0]} and ${uniqueClassDays[1]}`
                } else {
                  cls.classDaySummary = `${uniqueClassDays[0]}s and ${uniqueClassDays[1]}s`
                }
              } else if (uniqueClassDays.length === 5) {
                cls.classDaySummary = `${uniqueClassDays[0]} – ${uniqueClassDays[uniqueClassDays.length - 1]}`
              } else if (uniqueClassDays.length > 2) {
                if (cls.sessions == uniqueClassDays.length) {
                  cls.classDaySummary = uniqueClassDays.join(', ')
                } else {
                  cls.classDaySummary = uniqueClassDays.map((d) => `${d}s`).join(', ')
                }
              } else {
                cls.classDaySummary = ''
              }
              // console.log(cls.shortTitle, cls.classDaySummary, uniqueClassDays)
            }

            if (siteConfig.features.classDateOverrides && siteConfig.features.classDateOverrides[cls.code]) {
              cls.dateSummary = siteConfig.features.classDateOverrides[cls.code]
            } else {
              if (cls.classDaySummary) {
                if (cls.daySales && cls.classDays.length) {
                  console.log(cls.shortTitle, cls.classDays)
                  cls.dateSummary = `${cls.classDaySummary}, ${timestampsToDateRange(
                    cls.classDays[0].dateFmt,
                    cls.endDate
                  )}`
                } else {
                  cls.dateSummary = `${cls.classDaySummary}, ${timestampsToDateRange(cls.startDate, cls.endDate)}`
                }
              } else {
                cls.dateSummary = `${timestampsToDateRange(cls.startDate, cls.endDate)}`
              }

              if (
                !cls.daySales &&
                cls.classDaySummary &&
                cls.sessions > 1 &&
                !siteConfig.features.hideClassSessionCount
              ) {
                cls.dateSummary += ` (${cls.sessions} sessions)`
              }
            }

            // if (cls.catalogId === 1068)
            //   console.log('uniqueStartTimes', uniqueStartTimes, 'uniqueEndTimes', uniqueEndTimes)

            if (siteConfig.features.classDateOverrides && siteConfig.features.classDateOverrides[cls.code]) {
              cls.timeSummary = siteConfig.features.classTimeOverrides[cls.code]
            } else {
              if (
                !cls.daySales &&
                cls.classDays.length > 1 &&
                cls.classDays.length <= 3 &&
                (uniqueStartTimes.length > 1 || uniqueEndTimes.length > 1)
              ) {
                // When 2 or 3 day workshops with different times, show better summary
                // console.log('classDays', cls.classDays)
                cls.timeSummary = cls.classDays
                  .map((d) => `${d.classDay} ${d.startTimeFmt} - ${d.endTimeFmt}`)
                  .join(', ')
              } else if (uniqueStartTimes.length > 1 || uniqueEndTimes.length > 1) {
                // || (cls.daySales && uniqueClassDays.length === 1)
                // if (cls.catalogId === 826) console.log('various times', uniqueStartTimes, uniqueEndTimes)
                cls.timeSummary = 'Various times'
              } else {
                if (cls.daySales && cls.classDays.length === 1) {
                  cls.timeSummary = `${cls.classDays[0].startTimeFmt} – ${cls.classDays[0].endTimeFmt}`
                } else {
                  cls.timeSummary = `${cls.startTime} – ${cls.endTime}`
                }
              }
            }

            if (cls.daySales) {
              let openSlots = 0
              cls.classDays.forEach((day) => {
                if (day.limit - day.daysSold > 0) {
                  openSlots += day.limit - day.daysSold
                }
                // if (cls.catalogId === 826) console.log('day.limit', day.limit, 'day.daysSold', day.daysSold, day.limit - day.daysSold, 'openSlots', openSlots)
              })
              cls.classIsFull = openSlots <= 0
              // if (cls.catalogId === 826) console.log('classIsFull', cls.classIsFull)

              if (cls.fee) {
                cls.feePerSession = parseFloat((cls.fee / cls.classDays.length).toFixed(2))
                console.log('cls.feePersonSession', cls.feePerSession)
              } else {
                cls.feePerSession = 0
              }
            } else {
              cls.classIsFull = cls.seatsAvailable <= 0
            }
          })

          console.log('openClasses.length', openClasses.length)
          openClasses = openClasses.filter((c) => !c.cancelled)

          // openClasses.forEach(c => {
          //   console.log(c.shortTitle, 'startDateStandard', c.startDateStandard, 'endDateStandard', c.endDateStandard)
          // })

          if (siteConfig.features?.hideStartedClasses) {
            openClasses = openClasses.filter((c) => {
              if (!c.daySales) {
                return c.startDateStandard >= todaysDateStandard
              } else {
                const validDays = c.classDays.filter((d) => {
                  // console.log('d.dateStandard', d.dateStandard, startDate, endDate, d.dateStandard >= startDate && d.dateStandard <= endDate)
                  return d.dateStandard >= todaysDateStandard
                })
                // console.log('validDays', validDays)
                return validDays.length > 0
              }
            })
          }

          if (siteConfig.features?.hideEndedClasses) {
            openClasses = openClasses.filter((c) => {
              return c.endDateStandard >= todaysDateStandard
            })
          }

          const programMediums = []
          const secondaryMediums = []
          const categories = []
          const semesters = []
          const levels = []
          const openClassInstructorIds = []
          const activeClassInstructorIds = []
          const openClassProgramIds = []
          const locations = []
          let sites = [] // XXX

          if (siteConfig.key === 'perkins') {
            sites = ['Collingswood', 'Moorestown']
          }

          openClasses.forEach((cls) => {
            // console.log('instructorId', cls.instructorId)
            if (cls.eventId && !openClassProgramIds.includes(cls.eventId)) {
              openClassProgramIds.push(cls.eventId)
            }

            // console.log('class mediums', cls.medium) // cls.medium1, cls.medium2
            let allMediaOk = siteConfig.features.keepAllMedia

            if (cls.medium && !programMediums.includes(cls.medium)) {
              if (cls.medium.trim() !== 'None' && (allMediaOk || (cls.medium1 && cls.medium1.trim() !== 'All Media'))) {
                programMediums.push(cls.medium)
              } else {
                cls.mediumWas = cls.medium
                cls.medium = ''
              }
            }
            if (cls.medium1 && !secondaryMediums.includes(cls.medium1)) {
              if (cls.medium1.trim() !== 'None' && (allMediaOk || cls.medium1.trim() !== 'All Media')) {
                secondaryMediums.push(cls.medium1)
              }
            }
            if (cls.medium2 && !secondaryMediums.includes(cls.medium2)) {
              if (cls.medium2.trim() !== 'None' && (allMediaOk || cls.medium2.trim() !== 'All Media')) {
                secondaryMediums.push(cls.medium2)
              }
            }

            if (cls.department && !categories.includes(cls.department)) {
              categories.push(cls.department)
            }

            // console.log('semesterName', cls.semesterName, semesters.includes(cls.semesterName))
            if (cls.semesterName && !semesters.includes(cls.semesterName)) {
              semesters.push(cls.semesterName)
            }

            // console.log('classLevel', cls.classLevel, levels.includes(cls.classLevel))
            let level = cls.classLevel?.trim()
            if (level && !levels.includes(level) && !level.toLowerCase().includes('all') || level.toLowerCase().includes('any')) {
              levels.push(level)
            }

            if (cls.location && !locations.includes(cls.location)) {
              if (!cls.location.toLowerCase().includes('all')) {
                locations.push(cls.location)
              }
            }

            if (cls.location && siteConfig.key === 'perkins') {
              if (cls.location.indexOf('CW') === 0) {
                cls.site = 'Collingswood'
              } else if (cls.location.indexOf('MT') === 0) {
                cls.site = 'Moorestown'
              }
            }

            if (cls.instructorId && cls.instructorId !== 0) {
              if (!openClassInstructorIds.includes(cls.instructorId)) {
                if (!cls.isPastCutOffDate || siteConfig.features?.showClassesAfterCutOffDate) {
                  openClassInstructorIds.push(cls.instructorId)
                }
              }
              if (!activeClassInstructorIds.includes(cls.instructorId)) {
                activeClassInstructorIds.push(cls.instructorId)
              }
            }
            if (cls.instructor2Id && cls.instructor2Id !== 0) {
              if (!openClassInstructorIds.includes(cls.instructor2Id)) {
                if (!cls.isPastCutOffDate || siteConfig.features?.showClassesAfterCutOffDate) {
                  openClassInstructorIds.push(cls.instructor2Id)
                }
              }
              if (!activeClassInstructorIds.includes(cls.instructor2Id)) {
                activeClassInstructorIds.push(cls.instructor2Id)
              }
            }
          })

          // if (siteConfig.features.separateClassesAndWorkshops) {
          //   this.openWorkshops = openClasses.filter(c => c.division === 'workshop')
          //   this.openClasses = openClasses.filter(c => c.division === 'class')
          // } else {
          //   this.openClasses = openClasses
          // }

          if (siteConfig.features.showOpenClassesAboveClosed) {
            openClasses = openClasses.sort((a, b) => {
              if (a.isPastCutOffDate) return 1
              return -1
            })
          }

          this.openClasses = openClasses
          this.programMediums = programMediums.map((m) => m.trim()).sort()
          this.secondaryMediums = secondaryMediums.map((m) => m.trim()).sort()

          let allMediums = uniq([...this.programMediums, ...this.secondaryMediums])
            .map((m) => capitalizeFirstLetter(m))
            .sort()


          // XXX Should we also treat "All Media" as an exclusion instead of giving it special treatment?
          if (siteConfig.features?.mediumExclusions) {
            allMediums = allMediums.filter((m) => !siteConfig.features.mediumExclusions.includes(m))
          }

          this.mediums = allMediums

          if (siteConfig.features.categoryOrder) {
            const order = siteConfig.features.categoryOrder
            this.categories = categories.sort((a, b) => {
              // console.warn('order', a, order.indexOf(a), b, order.indexOf(b))
              if (order.indexOf(a) > order.indexOf(b)) return 1
              if (order.indexOf(a) < order.indexOf(b)) return -1
              return 0
            })
          } else {
            this.categories = categories.sort()
          }

          this.semesters = semesters.sort()

          if (siteConfig.features.classLevelOrder) {
            const order = siteConfig.features.classLevelOrder
            this.levels = levels.sort((a, b) => {
              if (order.indexOf(a) > order.indexOf(b)) return 1
              if (order.indexOf(a) < order.indexOf(b)) return -1
              return 0
            })
          } else {
            this.levels = levels.sort()
          }

          this.locations = locations.sort()
          this.sites = sites.sort()

          this.openClassProgramIds = openClassProgramIds
          instructorStore.updateOpenClassInstructorIds(openClassInstructorIds)
          instructorStore.updateActiveClassInstructorIds(activeClassInstructorIds)

          // console.log('programMediums', programMediums)
          // console.log('secondaryMediums', secondaryMediums)
          // console.log('all mediums',allMediums)
          // console.log('categories (departments)', categories)
          // console.log('openClassInsutrctorIds', openClassInstructorIds)

          //sort classes by the first class start date
          if (siteConfig.key === 'pittsburgh') {
            this.openClasses = this.openClasses.sort((a, b) => {
              if (a.classDays[0]?.dateStandard < b.classDays[0]?.dateStandard) return -1
              if (a.classDays[0]?.dateStandard > b.classDays[0]?.dateStandard) return 1
              return 0
            })
          }

          this.loadingClasses = false
        })
        .catch((error) => {
          this.loadingClasses = false
          console.log('error', error)
        })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useClassStore, import.meta.hot))
}
