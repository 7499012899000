<script setup>
import { toRefs, computed, inject } from 'vue'
import { useCartStore } from '@/stores/CartStore'
import { useCommonStore } from '@/stores/CommonStore'
import { XMarkIcon as XIcon } from '@heroicons/vue/24/outline';
// import CartButton from './CartButton.vue'

import { Popover, PopoverButton, PopoverPanel } from '@headlessui/vue'

const siteConfig = inject('siteConfig')

const donationImageUrl = `/sites/${siteConfig.key}/icon.png`

const props = defineProps({
  classes: {
    type: String, default: ''
  }
})

const { classes } = toRefs(props)

const cartStore = useCartStore()

const openedCart = computed(() => cartStore.openedCart)

const commonStore = useCommonStore()

const openCart = () => {
  cartStore.toggleCart(true)
}

const outsideCloseCart = () => {
  if (commonStore.windowInnerWidth >= 1280) {
    cartStore.toggleCart(false)
  }
}

const closeCart = () => {
  // console.warn('closeCart')
  cartStore.toggleCart(false)
}
</script>

<template>
  <Popover class="cart-modal">
    <!-- <CartButton :classes="`cart-btn ${classes}`" :text="'Cart'" /> -->
    <PopoverButton class="cart-btn" :class="classes" @click="openCart">
      <Icon icon="ant-design:shopping-cart-outlined" class="icon" /> Cart
    </PopoverButton>
    <transition name="translate">
      <PopoverPanel
        class="cart-popup"
        v-show="openedCart"
        v-click-outside="outsideCloseCart"
        static
      >
        <div class="container">
          <header>
            <h3 class="xlarge-text xl:large-regular">Your cart</h3>
            <button @click="closeCart">
              <XIcon class="icon" />
            </button>
          </header>

          <div class="in-cart-classes">
            <template v-if="cartStore.items.length">
              <div
                class="class-box"
                v-for="(item, index) in cartStore.items"
                :key="index"
              >
                <img
                  v-if="siteConfig.assets.giftCertificateImage && item.type === 'gift-certificate'"
                  :src="`/sites/${siteConfig.key}/${siteConfig.assets.giftCertificateImage}`"
                  class="class-img"
                />
                <img v-else-if="item.type === 'gift-certificate'" src="@/assets/images/gift.png" alt="" class="class-img" />                <img v-else-if="item.type !== 'donation'" class="class-img" :src="item.imageUrl" />
                <img v-else class="class-img" :src="donationImageUrl" />
                <div class="info">
                  <h4 v-html="item.title" class="small-bold"></h4>
                  <p v-html="item.subtitle" class="small-regular"></p>
                  <strong class="small-bold">
                    &dollar;{{ (item.effectiveCost || item.nonMemberCost).toFixed(2) }}
                  </strong>
                  <template v-if="item.discountNote">
                    &nbsp;<small class="text-xs italic text-orange-600" v-html="item.discountNote"></small>
                  </template>
                  <p v-if="item.type === 'account-payment' && item.items" class="class-instructor small-regular">
                    <template v-for="accountItem in item.items">
                      {{ accountItem.name }} – ${{ accountItem.amountDue }}<br>
                    </template>
                  </p>
                </div>
                <button
                  v-if="item.removable !== false"
                  class="btn-remove"
                  @click="cartStore.removeItem(item.cartId)"
                >
                  <img src="@/assets/icons/trash.svg" />
                </button>
              </div>
            </template>
            <div class="empty-cart" v-else>
              <h3 class="heading-3">Empty Cart</h3>
            </div>
          </div>

          <footer class="body-regular">
            <div class="row" v-if="cartStore.taxes">
              <span>Tax</span>
              <span>&dollar;{{ cartStore.taxes.toFixed(2) }}</span>
            </div>
            <div class="row" v-if="siteConfig.modules.shop && !cartStore.shouldPickupItems && cartStore.shipping">
              <span>Shipping</span>
              <span>&dollar;{{ cartStore.shipping.toFixed(2) }}</span>
            </div>
            <div class="row">
              <strong>{{ cartStore.items.length }}
                {{
                  !cartStore.items.length || cartStore.items.length > 1
                    ? 'items'
                    : 'item'
                }}</strong>
              <strong>
                Total:
                <span class="total-amount">${{ cartStore.total.toFixed(2) }}</span>
              </strong>
            </div>

            <div class="actions">
              <router-link
                :to="{ name: 'Checkout: Review Cart' }"
                class="checkout-link btn-accent-1"
                :class="{ disabled: !cartStore.items.length }"
                @click.prevent="closeCart"
              >
                Checkout
              </router-link>
              <button class="outlined-accent-1 btn" @click="closeCart">
                Continue Shopping
              </button>
            </div>
          </footer>
        </div>
      </PopoverPanel>
    </transition>
  </Popover>
</template>
