<script setup>
import { inject } from 'vue'
const siteConfig = inject('siteConfig')
</script>
<template>
  <footer class="storefront-footer">
    <div class="container">
      <div class="footer-grid">
        <div class="column store-info small-regular">
          <img :src="`/sites/${siteConfig.key}/${siteConfig.org.footerLogoFilename || siteConfig.org.logoFilename}`" class="store-logo">
          <ul v-if="!siteConfig.org.locations">
            <li>
              <strong class="inline-block mb-2.5" v-if="siteConfig.org.address0 || siteConfig.org.name" v-html="siteConfig.org.address0 || siteConfig.org.name"></strong><br>
              <span v-if="siteConfig.org.address1" v-html="siteConfig.org.address1"></span>
              <br v-if="siteConfig.org.address1">
              <span v-if="siteConfig.org.address2" v-html="siteConfig.org.address2"></span>
            </li>
            <li v-if="siteConfig.org.phone || siteConfig.org.fax">
              <span v-if="siteConfig.org.phone">Phone: <a class="info-link" :href="`tel:${siteConfig.org.phone.replace(/[ -.]/g, '')}`">{{ siteConfig.org.phone }}</a><br></span>
              <span v-if="siteConfig.org.fax">Fax: <a class="info-link">{{ siteConfig.org.fax }}</a><br></span>
            </li>
            <li>
              <a class="info-link" :href="`mailto:${siteConfig.org.email}`" v-html="siteConfig.org.email"></a>
            </li>
            <!-- <li> Box Office Hours: 10am-5pm Mon-Fri </li> -->
          </ul>
          <template v-if="siteConfig.org.locations">
            <ul v-for="(location, index) in siteConfig.org.locations" :class="{'mt-4': index > 0}">
              <li>
                <strong class="inline-block mb-2.5" v-if="location.address0 || siteConfig.org.name" v-html="location.address0 || siteConfig.org.name"></strong><br>
                <span v-if="location.address1" v-html="location.address1"></span>
                <br v-if="location.address1">
                <span v-if="location.address2" v-html="location.address2"></span>
              </li>
              <li v-if="location.phone || location.org.fax">
                <span v-if="location.phone">Phone: <a class="info-link" :href="`tel:${location.phone.replace(/[ -.]/g, '')}`">{{ location.phone }}</a><br></span>
                <span v-if="location.fax">Fax: <a class="info-link">{{ location.fax }}</a><br></span>
              </li>
              <li>
                <a class="info-link" :href="`mailto:${location.email}`" v-html="location.email"></a>
              </li>
            <!-- <li> Box Office Hours: 10am-5pm Mon-Fri </li> -->
            </ul>          
          </template>
        </div>
        <div class="column custom-links" v-for="column in siteConfig.footer.linkColumns" :key="column.name">
          <h2 class="column-heading xlarge-text" v-if="column.title">
            {{ column.title }}
          </h2>
          <ul>
            <li v-for="link in column.links" :key="link.url">
              <a :href="link.url" :target="link.target">{{ link.title }}</a>
            </li>
          </ul>
        </div>
        <div class="column custom-links" v-if="siteConfig.footer.linkColumns.length < 2">
        </div>
        <div class="column custom-links" v-if="siteConfig.footer.linkColumns.length < 1">
        </div>
        <div class="column social-media" v-if="siteConfig.footer.socialLinks">
          <h2 class="column-heading xlarge-text" v-if="siteConfig.footer.socialLinks.title">
            {{ siteConfig.footer.socialLinks.title }}
          </h2>
          <ul>
            <li v-for="link in siteConfig.footer.socialLinks.links" :key="link.url">
              <a :href="link.url" class="link">
                <Icon :icon="`fa-brands:${link.icon}`" class="icon" />
                {{ link.title }}
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="store-copyright">
      <div class="container">
        <p class="small-regular">
          Powered by <a :href="`https://www.artcentercanvas.com/?ref=${siteConfig.key}`" class="small-bold">Art Center Canvas</a> | Copyright © {{ (new Date()).getFullYear() }} {{ siteConfig.org.name }}. All rights reserved.
        </p>
      </div>
    </div>
  </footer>
</template>

<style lang="postcss" scoped>
.storefront-footer {
  @apply sticky top-full left-0 border-t pt-5 md:pt-7 xl:pt-10;
  a { @apply transition hover:text-accent-2 }
  .footer-grid {
    @apply mb-5 md:mb-7 grid md:grid-cols-3 xl:grid-cols-[1.4fr,1fr,1fr,1fr]
    md:gap-x-6 xl:gap-x-10 gap-y-10;
    .column {
      .column-heading {
        @apply text-accent-2 mb-5
      }
    }
    .store-info {
      @apply md:col-span-full xl:col-auto md:flex gap-x-10 items-start xl:block;
      .store-logo { @apply max-w-[200px] max-h-[100px] mb-5 xl:mb-7 }
      ul { @apply grid gap-y-2.5; }
      .info-link { @apply text-accent-2 hover:opacity-70 }
    }
    .custom-links, .social-media { ul { @apply grid gap-y-5 } }
    .social-media .link {
      @apply inline-flex items-center gap-x-2;
      .icon { @apply w-6 h-6 }
    }
  }
  .store-copyright {
    @apply border-t py-5 md:py-6;
    p {
      @apply md:text-center text-secondary-text;
      a {
        @apply border-b-2 border-gray-400 hover:border-accent-2;
      }
    }
  }
}
</style>
