function capitalizeFirstLetter(string) {
  if (!string) return ''
  return string.charAt(0).toUpperCase() + string.slice(1)
}

const stripHtml = (html) => {
  let tmp = document.createElement('DIV')
  tmp.innerHTML = html
  return tmp.textContent || tmp.innerText || ''
}

const truncateHtml = (markup, length = 200) => {
  const text = stripHtml(markup)
  const words = text.split(/[ \t\r\n]+/).map((word) => word.trim())
  // console.log('words', words)

  let output = ''
  for (let i = 0; i < words.length; i++) {
    if (output.length < length) {
      if (output.length + words[i].length < length) {
        output += `${words[i]} `
      }
    }
  }
  const trimmedOutput = output.trim()

  if (trimmedOutput) {
    return trimmedOutput.concat('&hellip;')
  }
  return ''
}

const cleanMarkup = (markup) => {
  // console.log('markup', markup.length, markup)
  let clean = markup
    .replace(/<p[^>]+>/g, '<p>')
    .replace(/<div[^>]+>/g, '<div>')
    .replace('<a ', `<a class="font-bold text-accent-1" `)
    // .replace(/<a[^>]+>/g, '<span>')
    // .replace(/<\/a>/g, '</span>')
    .replace(/<span[^>]+>/g, '<span>')
    .replace(/<li[^>]+>/g, '<li style="list-style-type: disc;">')
    .replace(/<ul[^>]+>/g, '<ul style="margin-top: 0; margin-bottom: 0; padding-inline-start: 20px;">')
    .replace(/<strong[^>]+>/g, '<strong>')
    .replace(/style="font-size: small;"/g, '')
    .replace(/<p>&nbsp;<\/p>/g, '')
    .replace(/<p><span><br \/><\/span><\/p>/g, '')
    .replace(/<span>&nbsp;<\/span>/g, '')
    .replace(/<span><br \/><\/span>/, '')
    .replace(/<span><strong><br \/><\/strong><\/span>/g, '')

  // const emailRegExp = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/
  // const matches = clean.match(emailRegExp)

  // if (matches) {
  //   matches.forEach(match => {
  //     clean = clean.replace(match, `<a class="font-bold text-accent-1" href="mailto:${match}">${match}</a>`)
  //   })
  // }

  const websiteRegExp = /(http[s]?:\/\/)?[a-zA-Z-]+\.[a-zA-Z-]+\.[a-zA-Z]{2,3}((\/)?([a-zA-Z0-9]+)?)*/g
  const cleanWithoutLinks = clean.replace(/<a[^>]+>/g, '<span>').replace(/<\/a>/g, '</span>')

  const websiteMatches = cleanWithoutLinks.match(websiteRegExp)
  if (websiteMatches) {
    websiteMatches.forEach((match) => {
      //  console.log('website match', match)
      clean = clean.replace(match, `<a class="font-bold text-accent-1" href="http://${match}">${match}</a>`)
    })
  }

  // console.log(md)
  return clean
}

const cleanUrl = (url) => {
  if (url.indexOf('http') !== -1) {
    return url
  }
  return `http://${url}`
}

const formatCurrency = (amount) => {
  return Number(parseFloat(amount).toFixed(2)).toLocaleString('en', {
    minimumFractionDigits: 2,
  })
}

const toCapitalize = (string) => {
  const stringWithCapitalize = string.split(' ').reduce((acc, char) => {
    char = char.charAt(0).toUpperCase() + char.slice(1).toLowerCase()
    acc.push(char)

    return acc
  }, [])

  return stringWithCapitalize.join(' ')
}

export { capitalizeFirstLetter, stripHtml, truncateHtml, cleanMarkup, cleanUrl, formatCurrency, toCapitalize }
