import { defineStore, acceptHMRUpdate } from 'pinia'
import axios from 'axios'
import { useCommon } from '@/hooks/useCommon'
import siteConfig from '@/config.json'

import { timestampToShortDateText } from '@/helpers/datetime'

import { useClassStore } from '@/stores/ClassStore'
import { useUserStore } from '@/stores/UserStore'
import { useAlternativeShopStore } from '@/stores/AlternativeShopStore'

export const useCartStore = defineStore('CartStore', {
  state: () => {
    return {
      items: [],
      loading: false,
      discountCode: null,
      discountDollarAmount: null,
      discountPercentage: null,
      discountOn: ['class'], // XXX
      salesItems: [],
      openedCart: false,
      shippingItemPrice: 0,
      shippingItemCategory: 0,
      totalTax: 0,
      totalShipping: 0,
      shouldPickupItems: Boolean(localStorage.getItem('shouldPickupItems')),
      campaign: null,
      campaignId: null,
    }
  },

  getters: {
    checkoutAllowed() {
      if (siteConfig.features.requireUniqueAttendeesForSameClass) {
        const dupes = this.items.filter((item) => item.duplicateAttendee)
        if (dupes.length) {
          return false
        }
      }
      return true
    },

    shouldNotAddDonationAutomatically() {
      return sessionStorage.getItem('shouldNotAddDonationAutomatically')
    },

    donationAmount() {
      const donationItemAmount = this.itemsForAccount.find((item) => item.type === 'donation')
      return donationItemAmount ? donationItemAmount.effectiveCost : 0
    },

    haveMembershipInCart() {
      let membershipInCart = this.items.find((item) => item.type === 'membership')
      console.log('membershipInCart', membershipInCart)
      if (membershipInCart) return true
      return false
    },
    membershipsInCart() {
      return this.items.filter((item) => item.type === 'membership')
    },
    inventoryInCart() {
      return this.items.filter((item) => item.type === 'inventory').length > 0
    },
    classPersonCounts() {
      const counts = {}
      this.items.forEach((item) => {
        if (item.type === 'class' && item.personId) {
          const key = `${item.catalogId}:${item.personId}`
          counts[key] = counts[key] || 0
          counts[key] += 1
        }
      })
      return counts
    },
    itemsForAccount() {
      const userStore = useUserStore()

      const items = this.items.map((item) => {
        let isDiscountMember = false

        if (userStore.hasWholeHouseholdMembershipWithDiscount) {
          isDiscountMember = true
        }

        // console.log('isDiscountMember', isDiscountMember)
        // console.log('userStore.isActiveMemberWithDiscount', userStore.isActiveMemberWithDiscount)

        // || (userStore.loggedIn && !item.personId)
        if (!isDiscountMember && userStore.isActiveMemberWithDiscount && item.personId === userStore.account.personId) {
          isDiscountMember = true
        } else if (this.membershipsInCart.length) {
          console.log('membershipsInCart', this.membershipsInCart)
          if (this.membershipsInCart.find((membership) => membership.household === true && membership.discount)) {
            console.log('1')
            isDiscountMember = true
          } else if (
            this.membershipsInCart.find((membership) => membership.personId === item.personId && membership.discount)
          ) {
            console.log('2')
            isDiscountMember = true
          }
        }

        if (isDiscountMember) {
          console.log('isDiscountMember', item.memberCost, item.nonMemberCost)
          item.effectiveCost = item.memberCost
        } else {
          console.log('not isDiscountMember', item.memberCost, item.nonMemberCost)
          item.effectiveCost = item.nonMemberCost
        }

        item.effectiveCostWithoutFees = item.effectiveCost

        if (item.fee) {
          item.effectiveCost += item.fee
        }

        if (this.discountPercentage) {
          // console.log('item.discountPercentage', item.discountPercentage)
          if (!this.discountOn || this.discountOn.includes(item.type)) {
            const discountAmount = item.effectiveCost * (this.discountPercentage / 100)
            item.effectiveCost = parseFloat((item.effectiveCost - discountAmount).toFixed(2))
            item.discount = discountAmount
          }
        }

        if (item.type === 'class') {
          const key = `${item.catalogId}:${item.personId}`
          // console.log('key', key, 'this.classPersonCounts[key]', this.classPersonCounts[key])
          if (item.personId && this.classPersonCounts[key] > 1) {
            item.duplicateAttendee = true
          } else {
            item.duplicateAttendee = false
          }
        }
        // console.log('item.effectiveCost', item.effectiveCost)
        return item
      })

      if (siteConfig.key === 'snowfarm' && userStore.groupIds.includes(415)) {
        // Snow Farm Workshop Access Program (WAP), 60% discount on one class
        const classes = items
          .filter((item) => item.type === 'class')
          .sort((a, b) => {
            if (a.effectiveCost < b.effectiveCost) return -1
            if (a.effectiveCost > b.effectiveCost) return 1
            return 0
          })
        if (classes.length) {
          const lowestCostClass = classes[0]
          console.log('lowestCostClass', lowestCostClass)
          const discountAmount = lowestCostClass.effectiveCostWithoutFees * 0.6
          lowestCostClass.effectiveCost = parseFloat((lowestCostClass.effectiveCost - discountAmount).toFixed(2))
          lowestCostClass.discount = discountAmount
          lowestCostClass.discountNote = '60% discount'
        }
      }

      return items
    },

    total() {
      let total = 0
      this.itemsForAccount.forEach((item) => (total += item.effectiveCost))

      if (this.totalTax) {
        total += this.totalTax
      }

      if (this.totalShipping && !this.shouldPickupItems && !localStorage.getItem('shouldPickupItems')) {
        total += this.totalShipping
      }

      return total
    },
    classesTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'class').forEach((item) => (total += item.effectiveCost))
      return total
    },
    inventoryTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'inventory').forEach((item) => (total += item.effectiveCost))
      return total
    },
    chargesTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'charge').forEach((item) => (total += item.effectiveCost))
      return total
    },
    membershipsTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'membership').forEach((item) => (total += item.effectiveCost))
      return total
    },
    donationsTotal() {
      let total = 0
      this.itemsForAccount.filter((item) => item.type === 'donation').forEach((item) => (total += item.effectiveCost))

      return total
    },
    accountPaymentsTotal() {
      let total = 0
      this.itemsForAccount
        .filter((item) => item.type === 'account-payment')
        .forEach((item) => (total += item.effectiveCost))
      return total
    },
    certificatesTotal() {
      let total = 0
      this.itemsForAccount
        .filter((item) => item.type === 'gift-certificate')
        .forEach((item) => (total += item.effectiveCost))
      return total
    },
    taxes() {
      let total = 0
      this.itemsForAccount
        .filter((item) => item.taxable)
        .forEach((item) => (total += (item.effectiveCost * siteConfig.features.taxRate) / 100))
      this.totalTax = total
      return total
    },
    shipping() {
      let total = 0
      this.itemsForAccount.forEach((item) => (total += item.shippingPrice || 0))

      this.totalShipping = total
      return total
    }
  },

  actions: {
    itemCodeToItem(code) {
      return this.salesItems.find((item) => item.item_code === code)
    },
    updateShouldPickupItems(value) {
      value ? localStorage.setItem('shouldPickupItems', true) : localStorage.removeItem('shouldPickupItems')
      this.shouldPickupItems = value
    },
    updateShouldNotAddDonationAutomatically(value = true) {
      sessionStorage.setItem('shouldNotAddDonationAutomatically', value)
    },
    updateDonationsTotal(donation) {
      let donationItem = this.items.find((item) => item.type === 'donation')

      if (!donationItem) {
        this.items.push(donation)
      } else {
        this.items = this.items.reduce((acc, item) => {
          if (item.type === 'donation') {
            item = { ...item, ...donation }
          }

          acc.push(item)
          return acc
        }, [])
      }

      this.saveItemsToLocalStorge()
    },
    updateCampaign(campaign) {
      this.campaign = { ...campaign }
    },
    async fetchSalesItems() {
      try {
        const response = await axios.get(`${siteConfig.apiBaseUrl}/reports/queries/salesitem-quantities`)
        if (response.data.ok) {
          this.salesItems = response.data.results
          }
      } catch (e) {
        console.log('fetchSalesItems error', e)
      }
    },
    applyDiscountCode(code) {
      if (siteConfig.key === 'essex') {
        if (code.toUpperCase() === 'LAWRENCE75') {
          this.discountCode = code
          this.discountPercentage = 75
        }
        if (code.toUpperCase() === 'LAWRENCE30') {
          this.discountCode = code
          this.discountPercentage = 30
        }
        if (code.toUpperCase() === 'SENIOR10') {
          this.discountCode = code
          this.discountPercentage = 10
        }
      }
      if (siteConfig.key === 'district-clay') {
        if (code.toUpperCase() === 'DCCSTUDENT') {
          this.discountCode = code
          this.discountPercentage = 10
        }
        if (code.toUpperCase() === 'DCCARTIST') {
          this.discountCode = code
          this.discountPercentage = 10
        }

        if (this.discountCode) {
          this.saveItemsToLocalStorge()
        }
      }
      if (siteConfig.key === 'chesapeake') {
        if (code.toUpperCase() === 'OPENHOUSE') {
          this.discountCode = code
          this.discountPercentage = 10
          this.discountOn = ['class']
        }
      }
      if (siteConfig.key === 'pratt') {
        let today = new Date()
        if (code.toUpperCase() === 'OH_PRATT_23' && today > new Date('2023-09-09') && today < new Date('2023-09-10')) {
          this.discountCode = code
          this.discountPercentage = 15
          this.discountOn = ['class']
        }
        if (
          code.toUpperCase() === 'SEPTEMBER_2023' &&
          today > new Date('2023-09-10') &&
          today < new Date('2023-09-18')
        ) {
          this.discountCode = code
          this.discountPercentage = 15
          this.discountOn = ['class']
        }
        if (
          code.toUpperCase() === 'TEST_PRATT_DISCOUNT_CODE' &&
          today > new Date('2023-09-06') &&
          today < new Date('2023-09-18')
        ) {
          this.discountCode = code
          this.discountPercentage = 1
          this.discountOn = ['class']
        }
      }
      if (this.discountCode) {
        this.saveItemsToLocalStorge()
      }
    },

    restoreCart() {
      const cartRaw = localStorage.getItem(`${siteConfig.key}Cart`)
      if (!cartRaw) return
      try {
        const cart = JSON.parse(cartRaw)
        if (cart.items) {
          this.items = cart.items
        }
        if (cart.discountCode) {
          this.applyDiscountCode(cart.discountCode)
        }

        // XXX temporary, can remove later.
        this.items.forEach((item) => {
          if (item.type === 'membership' && item.discount === undefined) {
            item.discount = true
          }
        })
      } catch (error) {
        console.log('error restoring cart', error)
      }
    },
    saveItemsToLocalStorge() {
      localStorage.setItem(
        `${siteConfig.key}Cart`,
        JSON.stringify({
          items: this.items,
          discountCode: this.discountCode,
        })
      )
    },
    calculateShippingPrice(data) {
      if (data && this.items.length) {
        this.items.forEach((item) => {
          if (item.shippingCategory < this.shippingItemCategory) {
            item.shippingPrice = item.shippingPrice2
          } else {
            this.shippingItemPrice = data.shippingPrice2
          }
        })
      }

      if (!data && this.items.length) {
        let largestShippingCategory = 0
        this.items.forEach((item) => {
          if (item.shippingCategory > largestShippingCategory) {
            largestShippingCategory = item.shippingCategory
          }
        })
        this.items.forEach((item) => {
          if (item.shippingCategory === largestShippingCategory) {
            item.shippingPrice = item.shippingPrice1
          } else {
            item.shippingPrice = item.shippingPrice2
          }
        })
      }
    },
    addItem(item) {
      this.items.push({ ...item, cartId: Date.now() })
      this.saveItemsToLocalStorge()
    },
    updatePersonIdForItem({ cartId, personId }) {
      console.log('updatePersonIdForItem cartId', cartId, 'personId', personId)
      const item = this.items.find((item) => item.cartId === cartId)
      if (item) {
        item.personId = personId
      }
      this.updateRegistrationFees()
      this.saveItemsToLocalStorge()
    },
    addClass(id, opts = {}) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      let classDetails = opts.details
      let override = false

      if (classDetails) {
        override = true
      } else {
        classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      }

      if (!classDetails) {
        return
      }

      console.log('addClass', id, 'classDetails', classDetails)

      let cartId = Date.now()

      const item = {
        type: 'class',
        catalogId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: classDetails.instructorName,
        cartId,
        memberCost: classDetails.costMember,
        nonMemberCost: classDetails.costNon,
        membersOnly: classDetails.membersOnly,
        imageUrl: classDetails.imageUrl,
        timeSummary: classDetails.timeSummary,
        dateSummary: classDetails.dateSummary,
        partialSale: classDetails.daySale,
      }

      if (override) {
        item.override = true
      }

      if (classDetails.fee) {
        item.fee = classDetails.fee
        item.feeId = classDetails.feeId
      }

      console.log('addClass item', item)
      this.items.push(item)
      this.saveItemsToLocalStorge()
      return cartId
    },
    addClassDay(id, scheduleId, details = null) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      let classDetails = details
      let override = false

      if (classDetails) {
        override = true
      } else {
        classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      }

      if (!classDetails) {
        return
      }

      const classDay = classDetails.classDays.find((d) => d.scheduleId === scheduleId)

      if (!classDay) {
        console.error('Could not find classDay with scheduleId', scheduleId, 'for catalogId', id)
        return
      }

      const item = {
        type: 'class',
        activityDayId: scheduleId,
        catalogId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: classDay.dateLabel,
        cartId: Date.now() * scheduleId,
        memberCost: classDetails.sessionCostMem,
        nonMemberCost: classDetails.sessionCostNon,
        membersOnly: classDetails.membersOnly,
        imageUrl: classDetails.imageUrl,
      }

      if (override) {
        item.override = true
      }

      if (classDetails.feePerSession) {
        item.fee = classDetails.feePerSession
        item.feeId = classDetails.feeId
      }

      console.log('addClassDay item', item)
      this.items.push(item)
      this.saveItemsToLocalStorge()
    },
    addWaitlistClass(id) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      const classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      const item = {
        type: 'class',
        catalogId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: 'Joining Waitlist',
        cartId: Date.now(),
        memberCost: 0,
        nonMemberCost: 0,
        imageUrl: classDetails.imageUrl,
        membersOnly: classDetails.membersOnly,
        joinWaitlist: true,
      }
      console.log('addWaitlistClass item', item)
      this.items.push(item)
      this.saveItemsToLocalStorge()
    },
    addWaitlistClassDay(id, scheduleId) {
      const classStore = useClassStore()
      const userStore = useUserStore()

      const classDetails = classStore.openClasses.find((c) => c.catalogId === id)
      const classDay = classDetails.classDays.find((d) => d.scheduleId === scheduleId)

      if (!classDay) {
        console.error('Could not find classDay with scheduleId', scheduleId, 'for catalogId', id)
        return
      }
      const item = {
        type: 'class',
        activityDayId: scheduleId,
        catalogId: classDetails.catalogId,
        personId: userStore.account?.personId,
        title: classDetails.shortTitle,
        subtitle: `Join Waitlist for ${classDay.dateLabel}`,
        cartId: Date.now() * scheduleId,
        memberCost: 0,
        nonMemberCost: 0,
        imageUrl: classDetails.imageUrl,
        membersOnly: classDetails.membersOnly,
        joinWaitlist: true,
      }

      console.log('addWaitlistClassDay item', item)
      this.items.push(item)
      this.saveItemsToLocalStorge()
    },
    addMembership(id, addon, discount = true, household = false, autoRenew = false) {
      const userStore = useUserStore()
      const membership = siteConfig.memberships.find((c) => c.categoryId === id)
      this.items.push({
        type: 'membership',
        categoryId: membership.categoryId,
        discount,
        household,
        personId: userStore.account?.personId,
        title: membership.title,
        cartId: Date.now(),
        memberCost: membership.price,
        nonMemberCost: membership.price,
        imageUrl: `/sites/${siteConfig.key}/${membership.image}`,
        fee: addon?.price,
        addOnId: addon?.id,
        autoRenew: autoRenew,
        subtitle: autoRenew ? 'Auto Renewing' : '',
      })
      this.saveItemsToLocalStorge()
    },
    addTicket(event, session, tier) {
      const userStore = useUserStore()
      this.items.push({
        type: 'ticket',
        scheduleId: session.scheduleId,
        eventId: event.teventId,
        ticketId: event.ticketIdForScheduleId[session.scheduleId],
        personId: userStore.account?.personId,
        title: event.name,
        subtitle: `${timestampToShortDateText(session.date)} – ${tier.name}`,
        cartId: Date.now(),
        memberCost: tier.cost,
        nonMemberCost: tier.cost,
        imageUrl: event.imageUrl,
      })
      this.saveItemsToLocalStorge()
    },
    addGiftCertificate(data) {
      this.items.push({
        type: 'gift-certificate',
        title: `$${data.amount.toFixed(2)} Gift Certificate`,
        subtitle: data.recipientName,
        memberCost: data.amount,
        nonMemberCost: data.amount,
        recipientName: data.recipientName,
        imageUrl: `/sites/${siteConfig.key}/${siteConfig.assets.giftCertificateImage}`,
        cartId: Date.now(),
        data,
      })
      this.saveItemsToLocalStorge()
    },
    addAccountPayment(data) {
      const userStore = useUserStore()
      const newItem = {
        type: 'account-payment',
        title: 'Account balance payment',
        subtitle: '',
        items: userStore.accountItems?.map((ai) => {
          return {
            name: ai.lineItem.itemName,
            amountDue: ai.lineItem.currTotalAmt - ai.lineItem.cumulativePaid,
          }
        }),
        memberCost: data.amount,
        nonMemberCost: data.amount,
        imageUrl: `/sites/${siteConfig.key}/icon.png`,
        cartId: Date.now(),
        data,
      }

      let existing = this.items.find((item) => item.type === 'account-payment')
      if (existing) {
        this.removeItem(existing.cartId)
      }

      this.items.push(newItem)
      this.saveItemsToLocalStorge()
    },
    addInventory(data, opts = {}) {
      const userStore = useUserStore()
      this.items.push({
        type: 'inventory',
        inventoryId: data.inventoryId,
        itemId: data.inventoryId,
        itemCode: data.itemCode,
        glAccount: data.glAccount,
        personId: data.personId || userStore.account?.personId,
        recurring: data.recurring || false,
        title: data.title,
        subtitle: data.subtitle,
        memberCost: data.memberCost,
        nonMemberCost: data.nonMemberCost,
        imageUrl: data.imageUrl,
        cartId: Date.now(),
        parentId: data.parentId,
        removable: data.removable === false ? false : true,
        hidePersonPicker: data.hidePersonPicker,
      })
      this.saveItemsToLocalStorge()
    },
    addShopItem(data) {
      const { getImageUrl } = useCommon()
      const userStore = useUserStore()

      this.shippingItemCategory = data.shippingCategory
      this.shippingItemPrice = data.shippingPrice1

      this.calculateShippingPrice(data)

      this.items.push({
        cartId: Date.now(),
        title: data.itemName,
        type: 'inventory',
        effectiveCost: data.price,
        memberCost: data.price,
        nonMemberCost: data.price,
        shippingCategory: data.shippingCategory,
        personId: userStore.account?.personId,
        imageUrl: data.itemImage ? getImageUrl(data.itemImage) : `/sites/${siteConfig.key}/logo.jpg`,
        id: data.id,
        taxable: data.taxable,
        shippingPrice1: data.shippingPrice1,
        shippingPrice2: data.shippingPrice2,
        shippingPrice3: data.shippingPrice3,
        shippingPrice: this.shippingItemPrice,
      })
      this.saveItemsToLocalStorge()
    },
    addCharge(data, opts = {}) {
      const userStore = useUserStore()
      this.items.push({
        type: 'charge',
        itemId: data.itemId,
        glAccount: data.glAccount,
        personId: data.personId || userStore.account?.personId,
        title: data.title,
        subtitle: data.subtitle,
        memberCost: data.memberCost,
        nonMemberCost: data.nonMemberCost,
        imageUrl: data.imageUrl,
        cartId: Date.now(),
        parentId: data.parentId,
        removable: data.removable === false ? false : true,
      })
      this.saveItemsToLocalStorge()
    },

    updateRegistrationFees() {
      if (siteConfig.key !== 'snowfarm') return

      const userStore = useUserStore()

      const imageUrl = `/sites/${siteConfig.key}/icon.png`

      this.items
        .filter((item) => item.type === 'class')
        .forEach((item) => {
          // 2388 is high school app fee which is done using a class registration
          if (item.catalogId === 2388) return

          if (item.joinWaitlist) return

          if (!item.personId) return

          const paidRegFee = userStore.registrationFees.find((fee) => fee.registrant_id === item.personId)

          if (paidRegFee) return

          const regFeeInCart = this.items.find(
            (feeItem) => feeItem.title === 'Registration Fee' && feeItem.personId === item.personId
          )

          if (!regFeeInCart) {
            const regFeeItem = {
              itemId: 1099,
              personId: item.personId,
              glAccount: '4151',
              title: 'Registration Fee',
              subtitle: 'Annual Fee',
              memberCost: 35,
              nonMemberCost: 35,
              imageUrl,
              removable: false,
            }
            this.addCharge(regFeeItem)
          } else {
            regFeeInCart.removable = false
          }
        })

      this.items
        .filter((feeItem) => feeItem.title === 'Registration Fee')
        .forEach((fee) => {
          const classItemsForPerson = this.items.filter(
            (item) => item.type === 'class' && item.personId === fee.personId
          )
          if (!classItemsForPerson.length) {
            fee.removable = true
          }
        })
    },

    removeItem(cartId) {
      const alternativeShopStore = useAlternativeShopStore()
      if (!cartId) {
        this.updateShouldNotAddDonationAutomatically()
        this.items = this.items.filter((item) => item.type !== 'donation')
        this.saveItemsToLocalStorge()
        return
      }

      const cartItem = this.items.find((item) => item.cartId == cartId)
      const membersOnlyCartItems = this.items.filter((item) => !!item.membersOnly)

      console.log('cartItem', cartItem)
      if (cartItem.type === 'membership' && membersOnlyCartItems.length > 0) {
        // XXX TODO – in the future maybe show an alert to users that removing membership will remove membersOnly items
        console.log('removing membership from cart')
        const userStore = useUserStore()

        const otherMemberships = this.items.filter((item) => item.type === 'membership' && item.cartId !== cartId)

        console.log(
          'otherMemberships.length',
          otherMemberships.length,
          'userStore.isActiveMember',
          userStore.isActiveMember
        )
        // if (otherMemberships.length === 0) { //  && !userStore.isActiveMember
        // there are no other memberships in the cart, remove membersOnly items
        if (userStore.isActiveMember) {
          console.log('is active member, removing membership only')
          const newItems = this.items.filter((item) => item.cartId !== cartId)
          this.items = newItems
        } else if (otherMemberships.length === 0) {
          console.log('not active member, not other membership in cart, removing membersOnly items')
          const newItems = this.items.filter((item) => item.cartId !== cartId && !item.membersOnly)
          this.items = newItems
        }
        // }
      } else {
        const newItems = this.items.filter((item) => item.cartId !== cartId && item.parentId !== cartId)
        this.items = newItems

        if (siteConfig.key === 'snowfarm') {
          const classesInCart = this.items.filter(
            (item) => item.type === 'class' && item.personId == cartItem.personId && !item.joinWaitlist
          )
          const regFeeInCart = this.items.find(
            (item) => item.title === 'Registration Fee' && item.personId === cartItem.personId
          )

          if (classesInCart.length === 0 && regFeeInCart) {
            regFeeInCart.removable = true
          }
        }
      }

      this.calculateShippingPrice()
      this.saveItemsToLocalStorge()
    },
    emptyCart() {
      this.items = []
      this.discountCode = null
      this.discountPercentage = null
      this.discountDollarAmount = null
      this.discountOn = null
      localStorage.removeItem(`${siteConfig.key}Cart`)
    },
    filterOutBadItems() {
      const classStore = useClassStore()

      // 5 days in milliseconds
      const maxAge = 5 * 24 * 60 * 60 * 1000

      const filteredItems = this.items.filter((item) => {
        // First, get rid of any items older than 5 days
        const now = new Date()
        const timestamp = new Date(item.cartId)
        const diff = now - timestamp

        // console.log('now', now, 'timestamp', timestamp, 'diff', diff, 'maxAge', maxAge )
        if (diff > maxAge) {
          return false
        }

        // remove any class not currently in the catalog
        if (!classStore.loading && item.type === 'class' && !item.override) {
          const cls = classStore.filteredList.find((c) => (c.catalog_id = item.catalogId))
          // console.log('cls', cls?.shortTitle)
          if (!cls) {
            return false
          }
        }
        return true
      })

      if (this.items.length != filteredItems.length) {
        this.items = filteredItems
        this.saveItemsToLocalStorge()
      }
    },
    toggleCart(value) {
      this.openedCart = value
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useCartStore, import.meta.hot))
}
