<script setup>
import { onMounted, computed, provide, watch } from 'vue'
import { useRouter, useRoute } from 'vue-router'

import StoreHeader from './components/StoreHeader/index.vue'
import StoreFooter from './components/StoreFooter.vue'
import ScrollToTop from './components/ScrollToTop.vue'

import AltFooter from './components/AltFooter.vue'

import { useClassStore } from '@/stores/ClassStore'
import { useUserStore } from '@/stores/UserStore'
import { useCartStore } from '@/stores/CartStore'
import { useEventStore } from '@/stores/EventStore'
import { useCommonStore } from '@/stores/CommonStore'
import { useInstructorStore } from '@/stores/InstructorStore'

import Bend from '@/bend/BendClient'

import siteConfig from '@/config.json'
import axios from 'axios'
// console.log('siteConfig', siteConfig)

provide('siteConfig', siteConfig)
provide('bend', Bend)

const classStore = useClassStore()
const userStore = useUserStore()
const cartStore = useCartStore()
const eventStore = useEventStore()
const commonStore = useCommonStore()
const instructorStore = useInstructorStore()

const route = useRoute()
const router = useRouter()

const ready = computed(() => {
  console.log('route.matched', route.matched, 'len', route.matched.length)
  return route.matched && route.matched.length
})

// XXX TODO Instead of this hack, use route.meta to set a flag and check for it here?

const removeDefaultHeader = computed(() => {
  if (route.matched && route.matched.length) {
    if (
      route.matched[0].name === 'Submissions: index' ||
      route.matched[0].name === 'Purchase: index' ||
      route.matched[0].name === 'Checkout: index' ||
      route.matched[0].path === '/docs' ||
      route.matched[0].path.includes('/onboarding') ||
      route.matched[0].path.includes('/cart-add') ||
      route.matched[0].path.includes('/reports')
    ) {
      return true
    } else {
      return false
    }
  } else {
    return false
  }
})

onMounted(async () => {
  // KN: This was added because they accidentally did a mailing with a link to this 
  // preview site early on and their students started going to it and were getting
  // SSL errors. It's probably ok to disable at this point, but leaving here in case 
  // it needs to be restored
  // if (window.location.hostname === 'dev.potters.torpedo.io') {
  //   window.location = 'https://canvas.berkeleypottersstudio.org/classes'
  //   return
  // }

  if (siteConfig.modules.classes) {
    classStore.fetchOpenClasses()
    classStore.fetchPrograms()
    classStore.fetchRegistrationCounts()

    instructorStore.fetchInstructors()

    userStore.checkSession()

    cartStore.restoreCart()

    if (siteConfig.features.preAuthEnabled) {
      userStore.fetchPreAuthorized()
    }
  }

  if (siteConfig.modules.events) {
    eventStore.fetchEvents()
  }

  if (siteConfig.modules.donations && siteConfig.features.campaigns?.enabled) {
    userStore.fetchCampaigns()
  }

  if (siteConfig.key === 'snowfarm') {
    cartStore.fetchSalesItems()
    setInterval(() => {
      cartStore.fetchSalesItems()
    }, 45000)
  }

  commonStore.updateWindowWidth(window.innerWidth)
  window.addEventListener('resize', () => {
    commonStore.updateWindowWidth(window.innerWidth)
  })

  if (siteConfig.bendConfig) {
    Bend.logIn({
      username: siteConfig.bendConfig.username,
      password: siteConfig.bendConfig.password,
    }).then(() => {
      userStore.bendLoggedIn = true
    })
  }
})

watch(
  () => userStore.loggedOut,
  (loggedOut, prevLoggedOut) => {
    console.log('loggedOut', loggedOut, 'prevLoggedOut', prevLoggedOut)
    if (!prevLoggedOut && loggedOut && route.meta.requireAuth) {
      console.log('should redirect...')
      // XXX does this make sense? only really necessary if you're logging out
      // and on a page that requires login. otherwise user could stay on same page.
      router.push({ name: 'Store Classes' })
    }
  }
)

watch(
  () => userStore.loggedIn,
  (loggedIn, prevLoggedIn) => {
    console.log('loggedIn', loggedIn, 'prevLoggedIn', prevLoggedIn)
    if (!prevLoggedIn && loggedIn) {
      if (siteConfig.features?.fetchBalanceOnLoad) {
        userStore.fetchBalance()
      }
    }
  }
)

watch(
  () => [classStore.loadingClasses], () => {
    if (!classStore.loadingClasses) {
      console.log('classes have loaded')
      cartStore.filterOutBadItems()

    }
  }
)

if (siteConfig.key === 'potters') {

  let hasUpdatedDisplayOnly = false
  watch(
    () => [classStore.loadingClasses, userStore.loggedIn, userStore.isActiveMember],
    async () => {
      if (
        userStore.loggedIn &&
        !hasUpdatedDisplayOnly &&
        !classStore.loadingClasses &&
        classStore.openClasses.length
      ) {
        const now = new Date()
        const launch = new Date('2023-09-15T17:00:00.000Z') // noon PT
        const memberLaunch = new Date('2023-09-20T17:00:00.000Z')
        // console.log('launch', launch)

        if ((now > memberLaunch) && userStore.isActiveMember) {
          classStore.removeDisplayOnly()
          return
        }

        const enrolledStudentQueryUrl = `https://storefront-api.artcentercanvas.com/v1/${siteConfig.key}/reports/queries/potters/enrolled-students`
        if (now > launch) {
          axios.get(enrolledStudentQueryUrl).then(response => {
            const data = response.data
            // console.log('data', data)
            const ids = data.results.map(item => item.person_id)
            // console.log('ids', ids)
            // ids.push(17)
            const extras = [608, 118, 929, 209, 665, 1275, 1394]
            extras.forEach(id => ids.push(id))
            if (ids.includes(userStore.account.personId)) { //  || userStore.account.member
              // console.log('prereg user', userStore.account.personId)
              classStore.removeDisplayOnly()
            }
          })
        }
      }
    }
  )
}


if (siteConfig.key === 'snowfarm') {
    watch(
    () => userStore.groupIds,
    (groupIds) => {
      console.log('groupIds', groupIds)
      if (groupIds && groupIds.length) {
        
        // // TEMPORARY - hide classes that havent yet started unless in the group with id 432
        // if (userStore.groupIds.includes(432)) {
        //   classStore.fetchOpenClasses()
        // }
        
        if (groupIds.includes(410)) {
          classStore.removeDisplayOnly()
        }

        // const now = new Date()
        // const launch = new Date("2023-01-12T13:00:00.000Z")
        // const vipDate = new Date("2023-01-11T13:00:00.000Z")
        // const testerDate = new Date("2023-01-09T23:38:00.000Z")

        // if (now < launch) {
        //   console.log('updating classes...')
        //   let updateClasses = false
        //   if (groupIds.includes(405) && (vipDate < now)) {
        //     console.log('VIP')
        //     updateClasses = true
        //   }
        //   if (groupIds.includes(407) && (testerDate < now)) {
        //     console.log('Tester')
        //     updateClasses = true
        //   }

        //   if (updateClasses) {
        //     classStore.removeDisplayOnly()
        //   }
        // }
      }
    }
  )
}
</script>

<template>
  <template v-if="ready">
    <div :class="siteConfig.key">
      <StoreHeader v-if="!removeDefaultHeader" />

      <RouterView />

      <StoreFooter v-if="!removeDefaultHeader" />
      <AltFooter
        v-if="removeDefaultHeader && route.matched[0].path !== '/docs'"
      />

      <ScrollToTop v-if="!removeDefaultHeader" />
    </div>
  </template>
</template>

<style lang="postcss">
#app {
  @apply min-h-screen;
}
</style>
