import { defineStore, acceptHMRUpdate } from 'pinia'
import siteConfig from '@/config.json'
import axios from 'axios'

import { usDateFormatToYYYYMMDD, getNumberOfDays } from '@/helpers/datetime'
import { capitalizeFirstLetter, cleanMarkup } from '@/helpers/text'

export const useEventStore = defineStore('EventStore', {
  state: () => {
    return {
      events: [],
      groups: [],
    }
  },

  getters: {},

  actions: {
    fetchEvents() {
      this.loading = true
      console.log('fetchEvents')

      if (this.events.length) {
        console.log('events already loaded')
        return
      }

      axios
        .get(`${siteConfig.legacyApiBaseUrl}/tickets/allevents`, {
          auth: siteConfig.legacyApiAuth,
        })
        .then((response) => {
          console.log('response.data', response.data)
          let events = response.data
          events.forEach((event) => {
            if (event.image && event.image.itemImageFile) {
              event.imageUrl = `${siteConfig.legacyImagesBaseUrl}/system${event.image.itemImageFile}`
            } else {
              event.imageUrl = `/sites/${siteConfig.key}/${
                siteConfig.assets.defaultEventImage || siteConfig.assets.defaultClassImage
              }`
            }
            event.name = event.teventName
            event.id = event.teventId
            event.startDateStandard = usDateFormatToYYYYMMDD(event.startDate)
            event.endDateStandard = usDateFormatToYYYYMMDD(event.endDate)

            event.tiers = []
            event.costLow = null
            event.costHigh = null

            event.ticketIdForScheduleId = {}
            Object.keys(event.ticketMap).forEach((ticketId) => {
              event.ticketIdForScheduleId[event.ticketMap[ticketId]] = parseInt(ticketId)
            })

            for (let i = 1; i <= 4; i++) {
              if (event[`publishPrice${i}`]) {
                let cost = event[`cost${i}`]
                if (event.costLow === null) {
                  event.costLow = cost
                } else if (cost < event.costLow) {
                  event.costLow = cost
                }

                if (event.costHigh === null) {
                  event.costHigh = cost
                } else if (cost > event.costHigh) {
                  event.costHigh = cost
                }
                event.tiers.push({
                  name: event[`prname${i}`],
                  cost: cost,
                })
              }
            }
            if (event.tiers.length === 0) {
              event.costSummary = ''
            } else if (event.costLow === event.costHigh) {
              event.costSummery = `$${event.costLow}`
            } else {
              event.costSummary = `$${event.costLow.toFixed(2)} - $${event.costHigh.toFixed(2)}`
            }

            let today = new Date()
            let todaysDateStandard = today.toISOString().split('T')[0]
            event.upcomingSessions = event.sessions.filter((session) => {
              const sessionDateStandard = new Date(session.date).toISOString().split('T')[0]
              console.log('sessionDateStandard', sessionDateStandard, 'todaysDateStandard', todaysDateStandard)
              if (todaysDateStandard <= sessionDateStandard) {
                return true
              }
              return false
            })
          })

          this.events = events.sort((a, b) => {
            if (a.startDateStandard < b.startDateStandard) return -1
            if (a.startDateStandard > b.startDateStandard) return 1
            return 0
          })

          this.groups = this.events.map((evt) => evt.eventGroup).filter((grp) => !!grp)
        })
    },
  },
})

if (import.meta.hot) {
  import.meta.hot.accept(acceptHMRUpdate(useEventStore, import.meta.hot))
}
