import { defineStore } from 'pinia'
import axios from 'axios'
import capitalize from 'lodash/capitalize'
import siteConfig from '@/config.json'
import { useCommon } from '@/hooks/useCommon'

export const useAlternativeShopStore = defineStore('AlternativeShopStore', {
  state: () => {
    return {
      shopItems: [],
      artist: null,
      shopItem: null,
      artists: [],
      productCategories: [],
      productCategoriesBasedOnShopItems: [],
      productForms: [],
      productByArtist: [],
      selectedCategory: null,
      selectedForm: null,
      selectedArtist: null,
      selectedCategoryName: 'All products',
      sortBy: 'artistLastName',
      loadingProducts: false,
    }
  },
  getters: {
    preparedShopItems() {
      let shopItems = this.shopItems

      if (this.selectedCategory) {
        shopItems = shopItems.filter((item) => item.itemType === this.selectedCategory.toLowerCase())
      }

      if (this.selectedForm) {
        shopItems = shopItems.filter((item) => item.categoryName === this.selectedForm)
      }

      if (this.selectedArtist) {
        shopItems = shopItems.filter((item) => `${item.artistFirstName} ${item.artistLastName}` === this.selectedArtist)
      }

      if (this.sortBy) {
        shopItems = shopItems.sort((a, b) => a[this.sortBy].localeCompare(b[this.sortBy]))
      }

      return shopItems
    },
    sortByOptions() {
      return [
        { text: 'Artist Last Name', value: 'artistLastName' },
        { text: 'Artist First Name', value: 'artistFirstName' },
        { text: 'Item Name', value: 'name' },
        { text: 'Category', value: 'itemType' },
        { text: 'Form', value: 'categoryName' },
      ]
    },
    sortByText() {
      const option = this.sortByOptions.find((option) => option.value === this.sortBy)

      return option ? option.text : 'Artist Last Name'
    },
  },
  actions: {
    updateSelectedCategory(selectedCategory) {
      this.selectedCategory = selectedCategory
    },
    updateSelectedForm(selectedForm) {
      this.selectedForm = selectedForm
    },
    updateSelectedArtist(selectedArtist) {
      this.selectedArtist = selectedArtist
    },
    updateSortBy(sortBy) {
      let options = this.sortByOptions.map((option) => option.value)
      if (!options.includes(sortBy)) {
        sortBy = 'artistLastName'
      }
      this.sortBy = sortBy
    },
    updateShopItem(shopItem) {
      this.shopItem = shopItem
    },
    async fetchShopItems() {
      const { getImageUrl } = useCommon()
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitems?types=${siteConfig.features.shop.shopCategoriesTypes}`
        )

        if (!data.ok) return

        data.results.forEach((item) => {
          item.imageUrl = item.imageUrl ? getImageUrl(item.imageUrl) : null
        })

        this.shopItems = data.results
        this.loadingProducts = false
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchShopItems error', error)
      }
    },
    fetchProductCategoriesAndArtistsBasedOnShopItems() {
      const categories = {}
      const artists = {}
      let shopItems = this.shopItems

      shopItems.forEach((item) => {
        if (!categories[item.itemType]) {
          categories[item.itemType] = item.itemType
        }

        if (!artists[`${item.artistFirstName} ${item.artistLastName}`]) {
          artists[`${item.artistFirstName} ${item.artistLastName}`] = `${item.artistFirstName} ${item.artistLastName}`
        }
      })

      this.productByArtist = Object.keys(artists)
      this.productCategoriesBasedOnShopItems = Object.keys(categories)
    },
    async fetchProductFormsBasedOnShopItems() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-forms?types=${siteConfig.features.shop.shopCategoriesTypes}`
        )

        if (!data.ok) return

        this.productForms = data.results
      } catch (error) {
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async fetchProductCategories() {
      const productCategories = [
        {
          image: `/sites/${siteConfig.key}/shopCategory-${siteConfig.assets.shopCategoriesImages.allItemsCategory}`,
          heading: 'Shop All Items',
          route: '/shop/products',
        },
      ]

      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-types?types=${siteConfig.features.shop.shopCategoriesTypes}`
        )

        if (!data.ok) return

        productCategories.push(
          ...data.results.reduce((acc, item) => {
            acc.push({
              heading: `Shop ${capitalize(item.itemType)}`,
              image: `/sites/${siteConfig.key}/shopCategory-${siteConfig.assets.shopCategoriesImages[item.itemType]}`,
              route: '/shop/products',
              type: item.itemType,
            })

            return acc
          }, [])
        )

        productCategories.push(
          {
            image: `/sites/${siteConfig.key}/shopCategory-${siteConfig.assets.shopCategoriesImages.byForm}`,
            heading: 'Shop by Form',
            route: '/shop/shop-by-form',
          },
          {
            image: `/sites/${siteConfig.key}/shopCategory-${siteConfig.assets.shopCategoriesImages.byArtist}`,
            heading: 'Shop by Artist',
            route: '/shop/shop-by-artist',
          }
        )

        this.productCategories = productCategories
      } catch (error) {
        console.log('fetchProductCategories error', error)
      }
    },
    async fetchProductsByArtistId(artistId) {
      this.artist = {}
      this.loadingProducts = true
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-by-artist?artistId=${artistId}`
        )

        if (!data.ok) return

        this.artist = data.results[0]

        await this.fetchShopItems()

        this.shopItems = this.shopItems.filter((item) => item.artistId === this.artist.id)
      } catch (error) {
        this.loadingProducts = false
        console.log('fetchProductsByArtistId error', error)
      }
    },
    async fetchProductById(productId) {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-by-id?productId=${productId}`
        )

        if (!data.ok) return

        this.shopItem = data.results[0]
      } catch (error) {
        console.log('fetchProductById error', error)
      }
    },
    async fetchArtists() {
      try {
        const { data } = await axios.get(
          `${siteConfig.apiBaseUrl}/reports/queries/salesitem-artists?types=${siteConfig.features.shop.shopCategoriesTypes}`
        )

        if (!data.ok) return

        this.artists = data.results
      } catch (error) {
        console.log('fetchProductById error', error)
      }
    },
    clearFilters() {
      this.selectedCategory = null
      this.selectedForm = null
      this.selectedArtist = null

      this.productCategories = []
      this.productForms = []
      this.productByArtist = []
    },
    clearShopData() {
      this.shopItems = []
      this.artist = null
    },
  },
})
